<template>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="demo-input-suffix">
                <el-input placeholder="请输入需要查询的供应商名称" v-model="searchtext">
                  <template slot="prepend">供应商</template>
                </el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="without-outline">
              <el-button type="info" @click="resetting()"> 重置</el-button>
              <el-button type="primary" @click="loadItems()">查询</el-button>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="flex justifyend">
              <el-button type="primary" @click="dialogFormVisible = true">新增供应商</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-dialog :title="form._id ? '更新供应商' : '新增供应商'" :visible.sync="dialogFormVisible">
        <el-form :model="form">
            <el-form-item label="供应商全称" :label-width="formLabelWidth">
            <el-input
              v-model="form.suppliername"
              autocomplete="off"
              placeholder="请输入供应商公司全称"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人姓名" :label-width="formLabelWidth">
            <el-input
              v-model="form.contacts"
              autocomplete="off"
              placeholder="请输入供应商联系人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式" :label-width="formLabelWidth">
            <el-input
              v-model="form.phone"
              autocomplete="off"
              placeholder="请输入供应商联系人联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item label="地址" :label-width="formLabelWidth">
            <el-input
              v-model="form.address"
              autocomplete="off"
              placeholder="请输入供应商通讯地址"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-if="!form._id" @click="addsupplier(form)">添 加</el-button>
          <el-button type="primary" v-if="form._id" @click="editsupplier(form)">更 新</el-button>
        </div>
      </el-dialog>
      <el-table
        :data="items"
        style="width: 100%; margin-bottom: 20px"
        row-key="_id"
        border
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="suppliername" label="供应商全称"> </el-table-column>
        <el-table-column prop="contacts" label="联系人姓名"></el-table-column>
        <el-table-column prop="phone" label="联系方式"></el-table-column>
        <el-table-column prop="address" label="供应商地址"></el-table-column>
        <el-table-column prop="date" label="创建时间" :formatter="formatTime"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleedit(scope.row)">修改</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="handlePageChange"
        @size-change="handlePageSizeChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </template>
  <script>
  import { BButton } from "bootstrap-vue";
  export default {
    components: {
      BButton,
    },
    data() {
      return {
        editdialogFormVisible: false,
        dialogFormVisible: false,
        formLabelWidth: "120px",
        currentPage: 1, // 当前页码
        pageSize: 10, // 每页数据量
        total: 0, // 总数据量
        items: [],
        searchtext: "",
        search: "",
        searchdrug: "",
        searchsupplier: "",
        form: {
            _id:"",
            suppliername:"",
            contacts:"",
            phone:"",
            address:"",
            date:"",
        },
        editform: {
        },
        checkoptions: [],
        tableData: [],
      };
    },
    computed: {},
    methods: {
      resetting() {
        this.searchtext = ''
        this.loadItems();
      },
        editsupplier(data) {
            this.$api.inventory.editsupplier(data).then((res) => {
            this.$message({
                message: res.data.msg,
                type: "success",
            });
            this.loadItems();
            this.dialogFormVisible = false;
            });
        },
      handleedit(data) {
        this.dialogFormVisible = true;
        this.form = data
      },
      handleDelete(data) {
        this.$api.inventory.deletesupplier(data).then((res) => {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.loadItems();
        });
      },
      addsupplier(data) {
        this.form = {};
        this.$api.inventory.createsupplier(data).then((res) => {
            this.$message({
                message:res.data.msg,
                type: 'success'
            });
            this.dialogFormVisible = false;
            this.loadItems();
            this.form = {};
        });
      },
      handlePageChange(page) {
        this.currentPage = page;
        this.loadItems();
      },
      handlePageSizeChange(pageSize) {
        this.pageSize = pageSize;
        this.loadItems();
      },
      loadItems() {
        const data = {
          currentPage: this.currentPage,
          pageSize: this.pageSize,
          searchtext:this.searchtext
        };
        this.$api.inventory.getpagination(data).then((res) => {
          this.currentPage = res.data.pagination.currentPage;
          this.pageSize = res.data.pagination.pageSize;
          this.total = res.data.pagination.total;
          this.items = res.data.data;
        });
      },
      formatTime(row,column){
        let data = row[column.property]
        let dtime = new Date(data)
        const year = dtime.getFullYear()
        let month = dtime.getMonth() + 1
        if (month < 10) {
            month = '0' + month
        }
        let day = dtime.getDate()
        if (day < 10) {
            day = '0' + day
        }
        let hour = dtime.getHours()
        if (hour < 10) {
            hour = '0' + hour
        }
        let minute = dtime.getMinutes()
        if (minute < 10) {
            minute = '0' + minute
        }
        let second = dtime.getSeconds()
        if (second < 10) {
            second = '0' + second
        }
        return year+ '-' + month+ '-' + day + ' ' + hour + ':' + minute + ':' + second
        }
    },
    created() {
      this.loadItems();
    },
  };
  </script>
  <style>
  .flex{
    display: flex;
  }
  .justifyend{
    justify-content: end;
  }
  button:focus {
      outline: none;
  }
  .text {
    font-size: 14px;
  }
  
  .item {
    margin-bottom: 18px;
  }
  
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
  
  .box-card {
    width: 100%;
  }
  </style>
  